<template>
  <div class="card">
    <!-- Page Header Inside Card -->
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      :title="$t('command.detailsTitle')"
      :sub-title="command.reference"
      class="card-header"
      @back="goBack"
    >
    </a-page-header>

    <div class="command-info">
      <p><strong>{{ $t("command.clientName") }}:</strong> {{ command.createdBy.userName }}</p>
      <p><strong>{{ $t("command.date") }}:</strong> {{ formatDate(command.createdAt) }}</p>
      <p><strong>{{ $t("command.montant") }}:</strong> {{ totalAmount }} {{ $t("product.currency") }}</p>
      <p class="availability-status">
        <strong>{{ $t("command.status") }}:</strong>
        <span class="status-tag">
          <a-tag :color="command.status === 'inactive' ? 'red' : 'green'">
            {{ command.status === 'inactive' ? $t("command.inactive") : $t("command.active") }}
          </a-tag>
        </span>
      </p>
      
      <p><strong>{{ $t("command.state") }}:</strong> {{ command.state }}</p>
      <p v-if="command.note"><strong>{{ $t("command.note") }}:</strong> {{ command.note }}</p> 
    </div>

    <h3>{{ $t("command.productsInCommand") }}</h3>
    <a-table
      :columns="productColumns"
      :data-source="paginatedProducts"
      :pagination="paginationConfig"
      :rowKey="'_id'"
      @row-click="onProductRowClick"
    >
    <template slot="availability" slot-scope="text, record">
      <a-tag :color="getTagColor(record.availability)">
        {{ getTagText(record.availability) }}
      </a-tag>
    </template>
    
      <template slot="image" slot-scope="text, record">
        <img :src="record.coverPicture" alt="Product Image" style="width: 50px; height: 50px;" />
      </template>
    </a-table>
  </div>
</template>

<script>
import apiClient from '@/services/axios';

export default {
  data() {
    return {
      command: {
        reference: '',
        createdBy: '',
        createdAt: '',
        montant: 0,
        state: '',
        status: '',
        note: '', 
        marketProduct: []
      },
      currentPage: 1,
      pageSize: 5, 
    };
  },
  computed: {
    productColumns() {
      return [
        {
          title: this.$t("product.name"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: this.$t("product.quantity"),
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: this.$t("product.price"),
          dataIndex: "price",
          key: "price",
        },
        {
      title: this.$t("product.availability"),
      dataIndex: "availability",
      key: "availability",
      scopedSlots: { customRender: "availability" },
    },
        {
          title: this.$t("product.image"),
          dataIndex: "image",
          key: "image",
          scopedSlots: { customRender: "image" },
        },
      ];
    },
    // Compute total amount by summing up all product prices * quantity
    totalAmount() {
      return this.command.marketProduct.reduce((total, product) => {
        return total + product.price * product.quantity;
      }, 0);
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.command.marketProduct.slice(start, end);
    },
    paginationConfig() {
      return {
        current: this.currentPage,
        pageSize: this.pageSize,
        total: this.command.marketProduct.length,
        onChange: this.handlePageChange,
      };
    },
  },
  methods: {
    fetchCommandDetails(commandId) {
      apiClient.get(`/marketplace/command/${commandId}`)
        .then((response) => {
          const commandData = response.data;

          this.command = {
            reference: commandData.reference,
            createdBy: commandData.createdBy, 
            createdAt: commandData.createdAt,
            state: commandData.state,
            status: commandData.status,
            note: commandData.note, 
            marketProduct: commandData.marketProduct,
          };
        })
        .catch((error) => {
          console.error("Failed to fetch command details:", error);
          this.$message.error(this.$t('error.fetchCommand'));
        });
    },getTagText(availability) {
      switch (availability) {
        case 'available':
          return this.$t("product.available");
        case 'soon':
          return this.$t("product.comingSoon");
        case 'out':
          return this.$t("product.outOfStock");
        default:
          return this.$t("product.unavailable");
      }
    },
    getTagColor(availability) {
      switch (availability) {
        case 'available':
          return 'green';
        case 'soon':
          return 'orange';
        case 'out':
          return 'red';
        default:
          return 'gray';
      }
    },

    formatDate(date) {
      const d = new Date(date);
      return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`;
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    onProductRowClick(record) {
      this.$router.push(`/product/${record._id}`);
    },
    goBack() {
      this.$router.push('/marketPlace');
    }
  },
 
  created() {
    const commandId = this.$route.params.id;
    this.fetchCommandDetails(commandId);
  },
};
</script>

<style scoped>
.card {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.command-info p {
  margin: 10px 0;
}

.availability-status {
  margin-bottom: 20px; 
}

.status-tag {
  margin-left: 10px; 
}

h2 {
  margin-bottom: 20px;
}

h3 {
  margin-top: 40px;
}

img {
  max-width: 50px;
  max-height: 50px;
}

.card-header {
  margin-bottom: 20px;
}
</style>
